// GENERAL
// ---------------

$color1:              #e3ac1a;
$color2:              #dc8c00;
$color3:              #305450;
$color4:              #20413d;
$color5:              #231f20;

// TYPOGRAPHY
// ---------------

$textColor:           #231f20;

$baseFontSize:        16px;
$em-base:             $baseFontSize;
$baseLineHeight:      1.612;
$baseTypeSpacing:     round($baseFontSize * $baseLineHeight);
$baseFontFamily:      "Muli", Helvetica, Arial, sans-serif;
$secondaryFontFamily: $baseFontFamily;

$headingsFontFamily:  $secondaryFontFamily;
$headingsColor:       $color3;

$hrColor:             #DDD;